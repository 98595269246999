import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {UnmappedPlayerAudit} from "../../models/player-audit/unmapped/unmapped-player-audit.interface";
import {Observable} from "rxjs";
import {UnmappedPlayerAuditDetails} from "../../models/player-audit/unmapped/unmapped-player-audit-details.interface";
import {PlayerAuditSearchType} from "../../models/player-audit/player-audit-search-type.interface";
import {UnmappedPlayerAuditActivity} from "../../models/player-audit/unmapped/unmapped-player-audit-activity.interface";

import {environment} from "../../../environments/environment";
import {PlayerAudit} from "../../models/player-audit/player-audit.model";
import {PlayerAuditRocketmanExcel} from "../../models/player-audit/player-audit-rocketman-excel.interface";
import {PlayerAuditFastballsExcel} from "../../models/player-audit/player-audit-fastballs-excel.interface";
const baseUrl = environment.baseUrl;

@Injectable({
  providedIn: 'root'
})
export class PlayerAuditService {

  constructor(private http: HttpClient) { }

  public getPlayerAudit(searchType: number, searchParam: string, fromDateTime: string, toDateTime: string, allRocketRound: boolean, gameId: number): Observable<UnmappedPlayerAudit[]> {
    const url = baseUrl.concat('player-audit/all');
    return this.http.get<UnmappedPlayerAudit[]>(url, {
      params: {
        searchType: searchType,
        searchParam: searchParam,
        fromDateTime: fromDateTime,
        toDateTime: toDateTime,
        allRocketRound: allRocketRound,
        gameId: gameId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getPlayerAuditDetails(playerId: number, ticketId: number, gameId: number): Observable<UnmappedPlayerAuditDetails[]> {
    const url = baseUrl.concat('player-audit/details');
    return this.http.get<UnmappedPlayerAuditDetails[]>(url, {
      params: {
        playerId: playerId,
        ticketId: ticketId,
        gameId: gameId
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }

  public getPlayerAuditActivity(searchType: number, searchParam: string, fromDateTime: string, toDateTime: string): Observable<UnmappedPlayerAuditActivity[]> {
    const url = baseUrl.concat('player-audit/activity');
    return this.http.get<UnmappedPlayerAuditActivity[]>(url, {
      params: {
        searchType: searchType,
        searchParam: searchParam,
        fromDateTime: fromDateTime,
        toDateTime: toDateTime
      },
      headers: {
        'Authorization': 'Bearer ' + localStorage.getItem('token')
      }
    })
  }


  public getPlayerAuditSearchTypes(): PlayerAuditSearchType[] {
    return [
      {text: 'Username', value: 1},
      {text: 'PlayerID', value: 2},
      {text: 'ExternalID', value: 3},
      {text: 'E-mail', value: 4},
    ]
  }

  public playerAuditReportExcelMapper(data: PlayerAudit[], gameId: number): any[] {
    return data.map(item => gameId === 101 ? this.mapRocketmanPLayerAuditExcel(item) : this.mapFastballsPLayerAuditExcel(item));
  }

  private mapRocketmanPLayerAuditExcel(item: PlayerAudit): PlayerAuditRocketmanExcel {
    return {
      roundId: item.roundId,
      roundOpen: item.roundOpen,
      roundClose: item.roundClose,
      roundMultiplierTime: item.roundMultiplierTime,
      roundMultiplier: item.roundMultiplier,
      playerId: item.playerId,
      username: item.username,
      ticketId: item.ticketId,
      platformExtID: item.platformExtID,
      createdTime: item.createdTime,
      ticketStatus: item.ticketStatus,
      ticketType: item.ticketType,
      bet: item.bet,
      ticketMultiplier: item.ticketMultiplier,
      autoCashout: item.autoCashout,
      win: item.win,
      externalId: item.externalId
    }
  }

  private mapFastballsPLayerAuditExcel(item: PlayerAudit): PlayerAuditFastballsExcel {
    return {
      roundId: item.roundId,
      roundOpen: item.roundOpen,
      roundClose: item.roundClose,
      roundEnd: item.roundEnd,
      roundNumbers: item.roundNumbers,
      playerId: item.playerId,
      username: item.username,
      ticketId: item.ticketId,
      platformExtID: item.platformExtID,
      createdTime: item.createdTime,
      ticketStatus: item.ticketStatus,
      ticketType: item.ticketType,
      bet: item.bet,
      numbers: item.numbers,
      numbersHit: item.numbersHit,
      win: item.win,
      externalId: item.externalId
    }
  }

  public getGameInfo(url: string): Observable<string> {
    return this.http.get(url, {headers: {}, responseType: "text"});
  }
}
