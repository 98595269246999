import {Component, OnInit, ViewChild} from '@angular/core';
import {animate, state, style, transition, trigger} from "@angular/animations";
import {MatTableDataSource} from "@angular/material/table";
import {MatSort} from "@angular/material/sort";
import {PlayerAudit} from "../../models/player-audit/player-audit.model";
import {HandlingService} from "../../services/global-handling/handling.service";
import {LoadingService} from "../../services/loading/loading.service";
import {PlayerAuditService} from "../../services/player-audit/player-audit.service";
import {UnmappedPlayerAudit} from "../../models/player-audit/unmapped/unmapped-player-audit.interface";
import {LocalizationService} from "../../services/localization/localization.service";
import {PlayerAuditSearchType} from "../../models/player-audit/player-audit-search-type.interface";
import {PlayerAuditActivity} from "../../models/player-audit/player-audit-activity.model";
import {UnmappedPlayerAuditActivity} from "../../models/player-audit/unmapped/unmapped-player-audit-activity.interface";
import {UtilsService} from "../../services/utils/utils.service";
import {forkJoin} from "rxjs";
import {map} from "rxjs/operators";
import {ExcelService} from "../../services/excel/excel.service";
import {environment} from "../../../environments/environment";
import {MatDialog} from "@angular/material/dialog";
import {GameInfoComponent} from "../dialogs/game-info/game-info.component";

@Component({
  selector: 'app-player-audit',
  templateUrl: './player-audit.component.html',
  styleUrls: ['./player-audit.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class PlayerAuditComponent implements OnInit {

  public dataSource!: MatTableDataSource<PlayerAudit>;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;

  public showTable = false;
  public showActivityTable = false;
  public columns: string[] = [];
  public translationColumns: string[] = [];
  public expandedElement: PlayerAudit | null = null;

  public searchTypes: PlayerAuditSearchType[] = [];
  public selectedSearchType: PlayerAuditSearchType | null = null;
  public searchParam: string | null = null;
  public startDate: string | null = null;
  public endDate: string | null  = null;
  public startTime: string | null = '00:00';
  public endTime: string | null = '23:59';
  public allRocketRound: boolean = false;

  public defaultEndTime: string;

  public activityDataSource!: MatTableDataSource<PlayerAuditActivity>;
  public activityColumns: string[] = [];
  public activityTranslationColumns: string[] = [];

  public games = [
    {text: 'Rocketman', value: 101},
    {text: 'Fast Balls', value: 102},
  ];
  public currentGame = this.games[0];

  constructor(private playerAuditService: PlayerAuditService,
              private localizationService: LocalizationService,
              private handlingService: HandlingService,
              private loadingService: LoadingService,
              private utilsService: UtilsService,
              private excelService: ExcelService,
              private dialog: MatDialog) {
    this.searchTypes = playerAuditService.getPlayerAuditSearchTypes();
    this.activityTranslationColumns = localizationService.getPlayerAuditActivityTranslations();
    this.defaultEndTime = utilsService.getEndOfDayTime();

    this.searchParam = '';
    this.startDate = utilsService.getFirstDateInMonth();
    this.endDate = utilsService.getTodaysDate();
  }

  ngOnInit(): void {
  }

  private fetchReports(searchType: number, searchParam: string, fromDateTime: string, toDateTime: string, allRocketRound: boolean): void {
    this.loadingService.setLoadingSteps(1);

    forkJoin([
      this.playerAuditService.getPlayerAudit(searchType, searchParam, fromDateTime, toDateTime, allRocketRound, this.currentGame.value),
      this.playerAuditService.getPlayerAuditActivity(searchType, searchParam, fromDateTime, toDateTime)])
      .pipe(map(([playerAuditData, playerAuditActivity]) => {
        this.dataSource = new MatTableDataSource(this.mapData(playerAuditData));
        this.dataSource.sort = this.sort;

        if (this.dataSource.data.length === 0){
          this.hideReports();
        }else{
          this.showReports();
        }

        if (playerAuditActivity.length > 0){
          this.activityDataSource = new MatTableDataSource(this.mapActivityData(playerAuditActivity));
          this.activityColumns = this.activityDataSource.data[0].getFields();
          this.showActivityTable = true;
        }else{
          this.showActivityTable = false;
          this.activityDataSource = new MatTableDataSource<PlayerAuditActivity>([]);
        }
      }))
      .subscribe(
        () => this.loadingService.incrementLoadingSteps(),
        error => this.handlingService.error('Error fetching player information', error)
      );
  }

  private mapData(unmapped: UnmappedPlayerAudit[]): PlayerAudit[] {
    let data: PlayerAudit[] = [];

    unmapped.forEach(item => {
      const createdTime = item.CreatedTime ? this.utilsService.timestampToString(item.CreatedTime) : '';
      const roundOpen = this.utilsService.timestampToString(item.RoundOpen);
      const roundClose = this.utilsService.timestampToString(item.RoundClose);
      const roundMultiplierTime = item.RoundMultiplierTime ? this.utilsService.timestampToString(item.RoundMultiplierTime) : '';
      const roundEnd = item.RoundEnd ? this.utilsService.timestampToString(item.RoundEnd) : '';
      data.push(
        new PlayerAudit(
          item.RoundID,
          roundOpen,
          roundClose,
          roundMultiplierTime,
          roundEnd,
          item.RoundMultiplier ? item.RoundMultiplier : 0,
          item.RoundNumbers ? item.RoundNumbers : '',
          item.PlayerId,
          item.Username,
          item.TicketID,
          item.PlatformExtID,
          createdTime,
          this.mapTicketStatus(item.TicketStatus),
          this.mapTicketType(item.TicketType),
          item.Bet,
          item.TicketMultiplier ? item.TicketMultiplier : 0,
          item.Numbers ? item.Numbers : '',
          item.NumbersHit ? item.NumbersHit : '',
          item.AutoCashout ? item.AutoCashout : 0,
          item.Win,
          item.ExternalID)
      )
    });

    return data;
  }

  private mapTicketStatus(value: number): string {
    if (value === 0){
      return value + ' - canceled'
    }else if (value === 1) {
      return value + ' - active'
    }else if (value === 2) {
      return value + ' - loss'
    }else if (value === 3){
      return value + ' - win'
    }else {
      return '';
    }
  }

  private mapTicketType(value: number): string {
     if (value === 1) {
      return value + ' - standard'
    }else if (value === 2) {
      return value + ' - promo credit'
    }else if (value === 3){
      return value + ' - free ticket'
    }else {
       return ''
     }
  }

  private showReports(): void {
    this.columns = this.dataSource.data[0].columnData
      .filter(item => item.show ? item.show(this.currentGame.value) : true)
      .map(item => item.key);
    this.showTable = true;
  }

  private hideReports(): void {
    this.showTable = false;
    this.dataSource = new MatTableDataSource<PlayerAudit>([]);
  }

  public setStartDate(date: string | null): void {
    this.startDate = date;
  }
  public setEndDate(date: string | null): void {
    this.endDate = date;
  }
  public setStartTime(time: string | null): void {
    this.startTime = time;
  }
  public setEndTime(time: string | null): void {
    this.endTime = time;
  }

  private mapStartDate(): string {
    return this.startDate + ' ' + this.startTime + ':00';
  }

  private mapEndDate(): string {
    return this.endDate + ' ' + this.endTime + ':00';
  }

  public refreshData(): void {
    if (this.startDate && this.endDate && this.startTime && this.endTime && this.searchParam && this.selectedSearchType){
      if (this.startDate > this.endDate) {
        [this.startDate, this.endDate] = [this.endDate, this.startDate];
      }
      this.fetchReports(this.selectedSearchType.value, this.searchParam, this.mapStartDate(), this.mapEndDate(), this.allRocketRound);
    }else {
      this.handlingService.error('Please fill all of the fields');
    }
  }

  public alignHeaderCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].align(column);
  }

  public headerCell(column: string): string {
    if (this.dataSource.data.length === 0){
      return 'normal';
    }
    return this.dataSource.data[0].field(column);
  }

  public total(column: string): string | number {
    if(column === this.columns[0]){
      return 'Total: ' + this.dataSource.data.length;
    }else if (column == this.columns[12] || column == this.columns[15]){
      let result = this.sumData(column);
      return column + 's ' + this.utilsService.formatMoney(result);
    }else{
      return '';
    }
  }

  private sumData(column: string): number {
    return this.dataSource.data.reduce((previousValue, currentValue) => previousValue + currentValue.columnData.find(item => item.key === column)!.total, 0);
  }

  private mapActivityData(unmapped: UnmappedPlayerAuditActivity[]): PlayerAuditActivity[] {
    const result: PlayerAuditActivity[] = [];
    unmapped.forEach(item => {
      const activityTime = this.utilsService.timestampToString(item.ActivityTime);
      result.push(
        new PlayerAuditActivity(
          item.Activity,
          activityTime,
        )
      );
    });
    return result;
  }

  public alignActivityHeaderCell(column: string): string {
    if (this.activityDataSource.data.length === 0){
      return 'normal';
    }
    return this.activityDataSource.data[0].align(column);
  }

  public downloadExcel(): void {
    if (!this.dataSource || this.dataSource.data.length < 1){
      this.handlingService.error('No data to download');
      return;
    }
    let data = this.playerAuditService.playerAuditReportExcelMapper(this.dataSource.data, this.currentGame.value);
    const prefix = this.currentGame.value === 101 ? '[Rocketman] ' : '[Fastballs] ';
    const name = prefix + 'Player audit report - ' + this.utilsService.getTodaysDate();
    this.excelService.exportAsExcelFile(data, name);
  }

  public translation(column: string): string {
    if (this.dataSource.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource.data[0].columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.column;
  }

  public sortHeader(column: string): string {
    if (this.dataSource.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource.data[0].columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.field;
  }

  public alignment(column: string): string {
    if (this.dataSource.data.length < 1){
      return '';
    }
    const columnMetaData = this.dataSource.data[0].columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.align;
  }

  public format(element: PlayerAudit, column: string): any {
    const columnMetaData = element.columnData.find(item => item.key === column);
    if (!columnMetaData){
      return '';
    }
    return columnMetaData.format;
  }

  public getGameInfo(element: PlayerAudit): void {
    this.loadingService.setLoadingSteps(1);
    const currentGameId = this.currentGame.value;
    const url = environment.gameInfoUrl.concat(currentGameId.toString()).concat('/').concat(element.ticketId.toString());
    this.playerAuditService.getGameInfo(url).subscribe(response => {
      this.dialog.open(GameInfoComponent, {
        width: '500px',
        autoFocus: false,
        data: response
      });
      this.loadingService.incrementLoadingSteps();
    }, () => this.loadingService.incrementLoadingSteps());
  }

}
