// baseUrl: "http://localhost:8080/",
// baseUrl: "http://192.168.2.120:8080/",
// baseUrl: "https://backoffice.rocketman.elbet.com/",

export const environment = {
  production: true,
  baseUrl: "https://online.backoffice.elbet.com:8081/",
  chatUrl: "wss://chat.rocketman.elbet.com:8765",
  chatSecret: "*&!)z@asdf&#)(!@u#",
  chatUsername: "ROCKETMAN",
  translateApiKey: "AIzaSyC7PtqmvGkiohHyAN7CXr6ytIkdXi4icKA",
  gameInfoUrl: "https://api.public.elbet.com/getGameInfo/"
};
